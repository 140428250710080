/* AmplifyのAuthenticatorをカスタムする */
@import '@aws-amplify/ui-react/styles.css';
/* @see https://ui.docs.amplify.aws/react/connected-components/authenticator/customization#additional-css-styling */

/* 全体 */
[data-amplify-router] {
  border: none !important;
  border-radius: 8px !important;
}

/* ヘッダー */
.amplify-heading {
  /* display: none !important; */
  text-align: center !important;
  font-weight: bold !important;
  font-size: 20px !important;
}

/* QRコード */
[data-amplify-qrcode] {
  margin: 0 auto !important;
}

/* フォーム全体 */
[data-amplify-form] {
  padding: 32px 32px 10px !important;
}
/* [data-amplify-form]::before {
  content: 'ログイン';
  display: block;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  padding-bottom: 24px;
} */

/* 各フォーム */
.amplify-input {
  border: none !important;
  background-color: #edf2f8 !important;
  padding: 10px 16px !important;
}
.amplify-input:focus {
  outline: none !important;
  box-shadow: none !important;
  border: 2px solid #11c2a3 !important;
}

/* ラベル */
.amplify-label {
  font-weight: bold !important;
}

/* パスワード表示切り替えボタン */
.amplify-field__show-password {
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  background-color: #edf2f8 !important;
}

/* ボタン */
.amplify-button[data-variation='primary'] {
  background-image: linear-gradient(to right, #43c7e3, #11c2a3) !important;
  padding: 10px 0 !important;
  margin-top: 15px !important;
  color: white !important;
}
.amplify-button--small {
  color: #a3a3a3 !important;
  margin-bottom: 10px !important;
  text-decoration: underline !important;
}

/* フッター */
[data-amplify-footer] {
  text-align: right !important;
}

[data-amplify-footer] > .amplify-button--small {
  margin-right: 20px !important;
}
